const LinkData = [
    {
        id:"about",
        label: "About",
        link: "/about",
        isSelected: false
    },
    {
        id:"home",
        label: "Home",
        link: "/",
        isSelected: false
    },
    {
        id:"arts",
        label: "Arts",
        link: "/arts",
        isSelected: false
    },
    {
        id:"apps",
        label: "Apps",
        link: "/apps",
        isSelected: false
    },
    {
        id:"css",
        label: "CSS",
        link: "/css",
        isSelected: false
    }
    // {
    //     id:"quotes",
    //     label: "Quotes",
    //     link: "/quotes",
    //     isSelected: false
    // },
    // {
    //     id:"articles",
    //     label: "Articles",
    //     link: "/articles",
    //     isSelected: false
    // }
];

export default LinkData;