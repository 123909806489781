import React, { useState, useEffect } from "react";
import { Link, withRouter } from 'react-router-dom';

import LinkData from "./LinkData"

import './Header.scss';


function getUpdateState (path) {
    const updateState = LinkData.map(data => {
        if (data.link === path) {
            return {
                ...data,
                isSelected: true
            }
        }

        return data;
    });

    return updateState;
}

function Header ({location}) {
    const {pathname} = location;
    const [state, setState] = useState(getUpdateState(pathname));
    const [menuState, setMenuState] = useState(false);

    useEffect(() => {
        setState(getUpdateState(pathname));
    },[pathname]);

    function updateTab (linkEl) {
        setMenuState(!menuState);
        if(linkEl.isSelected) return;
        window.scrollTo(0, 0);
        setState(state.map(link => {
            if(link.isSelected) {
                return {
                    ...link,
                    isSelected: false,
                }
            }
            if(link.id === linkEl.id) {
              return {
                ...link,
                isSelected: true,
              }
            }
            else return link;
          })
        )
    }

    const toggleLeftMenu = (event) => {
        event.preventDefault();
        setMenuState(!menuState);
    }

    return (
        <header className="darkpiece-header">
            <nav>
                <div className="title-area">
                    <ul>
                        <li>
                            <Link to="/">
                                <h1>
                                    <img
                                        src="https://github.com/Saurabh722/media.github.io/blob/master/assets/images/icons/darkpiece.png?raw=true"
                                        className="darkpiece-logo"
                                        alt="darkpiece logo"/>
                                    Dark Piece
                                </h1>
                            </Link>
                        </li>
                        <li className="menu">
                            <a
                                href="#close"
                                className={ menuState ? "navbar-handle close" : "navbar-handle" }
                                onClick= {toggleLeftMenu}
                                >
                                <span className="screen-reader">Close</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    className={ menuState ? "tab-container show" : "tab-container"}
                >
                <ul>
                    {
                        state.map(linkEl =>
                            <li key={linkEl.id}>
                                <Link
                                    to={linkEl.link}
                                    className={linkEl.isSelected ? "selected" : ""}
                                    onClick={(event) => {updateTab(linkEl)}}
                                    >
                                    {linkEl.label}
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </div>
            </nav>
      </header>
    )
}

export default withRouter(Header);