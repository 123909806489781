import React from "react";

import Cards from './../../components/cards/Cards';
import Iframe from './../../components/iframe/Iframe';
import utility from './../../utility/util';
import homeData from './homeData';

function Home () {
    return (
        <div className="darkpiece-home main-page">
            <div className="banner">
                <img src={utility.getUrl("images/timeline.jpg")} alt="my timeline"/>
            </div>
            <div className="home-comtainer page">
                {
                    homeData.map(cards =>
                        <div key={cards.id} className={cards.id}>
                            <div className="title-head full-width">{cards.title}</div>
                            {cards.id === "app-frames" && (
                                <Iframe frames={cards.frames}/>
                            )}
                            {cards.id !== "app-frames" && (
                                <Cards cards={cards.items} host={cards.host}/>
                            )}
                            <a href={cards.path} className="more-link">more...</a>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Home;