import React, { useRef, useEffect, useState } from 'react';
import validator from 'validator';

import Toast from './../toast';

const HOSTNAME = window.location.hostname === "darkpiece.com" ? "https://darkpiece-server.herokuapp.com" : "http://localhost:5000";

function ContactFrom () {
    const [toastState, setToastState] = useState({senderName: ""});
    const nameRef = useRef();
    const emailRef = useRef();
    const titleRef = useRef();
    const messageRef = useRef();
    let isDisabled = false;

    function onClickHandler (e) {
        e.currentTarget.style.border = null;
    }

    function validate(element) {
        if (!element.value) {
            element.style.border = '1px solid #ed4c27';
            return "";
        }

        return element.value
    }

    function isValid(data) {
        for (const key in data) {
            if (!data[key]) return false;
        }
        return true;
    }

    function submitMessage (e) {
        e.preventDefault();
        isDisabled = true;

        const data = {
            id: emailRef.current.value,
            name: validate(nameRef.current),
            email: validate(emailRef.current),
            title: validate(titleRef.current),
            message: validate(messageRef.current)
        }

        if (isValid(data)) {
            fetch(`${HOSTNAME}/api/sendmessage`,{ 
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)})
                .then(data => data.json())
                .then(({error}) => {
                    setToastState({
                        senderName: nameRef.current.value,
                        showToast: true,
                        error,
                    });
                    emailRef.current.value = "";
                    nameRef.current.value = "";
                    emailRef.current.value = "";
                    titleRef.current.value = "";
                    messageRef.current.value = "";
                    isDisabled = false;
                }
            );
        } else {
            console.log('validation Error');
        }
    }

    useEffect(() => {
        if (toastState.showToast) {
            setTimeout(() => {
                setToastState({
                    ...toastState,
                    senderName: "",
                    showToast: false
                });
            }, 5000);
        }
    }, [toastState]);

    return <div className="contact-form">
        <form>
            <fieldset>
                <input type="text" name="name" placeholder="Name" ref={nameRef} onChange={onClickHandler}/>
                <input type="email" name="email" placeholder="Email" ref={emailRef} onChange={onClickHandler}/>
            </fieldset>
            <fieldset>
                <input type="text" name="subject" placeholder="Subject" ref={titleRef} onChange={onClickHandler}/>
            </fieldset>
            <fieldset>
                <textarea placeholder="Message" ref={messageRef} onChange={onClickHandler}></textarea>
            </fieldset>
            <button onClick={submitMessage} disabled={isDisabled}>Submit</button>
        </form>
        {toastState.showToast && <Toast {...toastState}/>}
    </div>;
} 

export default ContactFrom