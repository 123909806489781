import React from "react";
import Iframe from './../../components/iframe/Iframe';
import AppData from './AppData';

import "./Apps.scss";

function Apps () {
    const state = {
        frames: AppData.apps
    }

    return (
        <div className="main-page darkpiece-app">
            <div className="app-comtainer page">
                <Iframe frames={state.frames}/>
            </div>
            <div className="page-intro">
                <p>
                    {AppData.description}
                </p>
            </div>
        </div>
    )
}

export default Apps;