import React from "react";

import SocialIcons from './../social-icons/SocialIcons';
import ContactForm from './ContactFrom ';

// styles
import './Footer.scss'; 

function Footer () {
    return (
        <footer className="darkpiece-footer">
            <div className="contact-me">
                <div className="title-head full-width title">
                    CONTACT ME
                </div>
                <div className="contact-container">
                    <ContactForm></ContactForm>
                    <div className="profile-info">
                        <div className="row">
                            <h3>Saurabh Sharma</h3>
                            <h4>Senior Frontend Web Developer</h4>
                        </div>
                        <div className="row">
                            <h4>Email:</h4>
                            <p>saurabhsharma722@live.com</p>
                        </div>
                        <div className="row">
                            <h4>Mobile:</h4>
                            <p>814777xxxx</p>
                        </div>
                        <SocialIcons/>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                © 2019 | DARKPIECE
            </div>
        </footer>
    );
}

export default Footer;