import React from "react";

import Cards from './../../components/cards/Cards';
import ArtData from './ArtData';

import "./Art.scss";

function Art () {
    const state = {
        title: ArtData.title,
        cards: ArtData.images,
        cards3d: ArtData.images3d,
    }

    return (
        <div className="darkpiece-art main-page">
            <div className="title-head full-width">3D Pencil Sketches</div>
            <div className="art-comtainer page">
                <Cards cards={state.cards3d} />
            </div>
            <div className="title-head full-width">Normal Pencil Sketches</div>
            <div className="art-comtainer page">
                <Cards cards={state.cards}/>
            </div>
        </div>
    )
}

export default Art;