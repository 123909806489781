import React from "react";

import Video from './../video/Video';

// styles
import './Iframe.scss'; 

function Iframe (props) {
    const frames = props.frames;

    return (
        <div className="frame-views">
            {
                frames.map(frame =>
                    <div key={frame.id} className="page-frame half-width">
                        {
                            frame.video ? 
                            <Video video={frame.video}/> :
                            <iframe title={frame.title} src={frame.src}></iframe>
                        }
                        <div className="card-details">
                            <p>{frame.description}</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Iframe;