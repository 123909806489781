const AppData = {
    title: "Saurabh web Apps",
    host: "https://github.com/Saurabh722/media.github.io/raw/master/assets/",
    description: "These web apps are part of my personal learning and exploring, nothing related to my current and previous Organizations.",
    apps: [{
            id: "app-h1",
            title: "Filmbox 3D Model",
            video: "videos/fbx.mp4",
            src: "https://fbx-demo.firebaseapp.com/",
            description: "Filmbox 3D Model, Using FBXLoader.js to show 3d fbx format file."
        },
        {
            id: "app-h2",
            title: "Interactive Video Web page",
            video: "videos/video-web.mp4",
            src: "https://interactive-video-web.firebaseapp.com/",
            description: "Interactive Video Web page, Video is taken from outer source with the help of play/pause and display various messages on pause make web page very unique."
        },
        {
            id: "app-h3",
            title: "CSS Presentation",
            video: "videos/scss-prasentation.mp4",
            src: "https://web-demo-416dd.web.app/",
            description: "CSS Presentation, which include pure css form with validation, animation, different ways to introduce css style, responsive web page, 5S way strategy, SCSS."
        },
        {
            id: "app-h7",
            title: "Kids Coding App",
            src: "#",
            video: "videos/first-step.mp4",
            description: "This App is user-friendly and intuitive. Takes a straight-forward approach, teaching real text-based code in a manner that even 8-year-olds can understand."
        },
        {
            id: "app-h4",
            title: "Corporate website",
            src: "https://helping-hands-ad3c1.web.app/",
            video: "videos/parallex.mp4",
            description: "Is a good example to create the parallax scrolling effect, with dynamic images and data loading."
        },
        {
            id: "app-h5",
            title: "Web housie Game",
            video: "videos/bingo.mp4",
            src: "https://housie-3217092.web.app/",
            description: "Web housie Game, with admin and client feature, where admin has rite to execute game and enable/disable winning criteria and reset game."
        },
        {
            id: "app-h6",
            title: "Scss Hierarchy",
            video: "videos/scss-hierarchy.mp4",
            src: "https://audit-typing.web.app/",
            description: "Scss Hierarchy, very usful while exploring file structure or while refactoring code."
        }
    ]
}

export default AppData;