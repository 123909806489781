const artData = {
    title: "Saurabh Pencil Sketchs",
    host: "https://raw.githubusercontent.com/Saurabh722/media.github.io/master/assets/",
    images3d: [
        {
            id: "3d-art-01",
            title: "3D sketch in 3d",
            image: "images/3d/3d_in_3d.jpg",
            date: "18 August 2012",
            description: "3D sketch Pencil Sketch"
        },
        {
            id: "3d-art-02",
            title: "Statue pencil sketch with 3d effect.",
            image: "images/3d/3d_statue.jpg",
            date: "22 January 2016",
            description: "3D sketch Pencil Sketch"
        },
        {
            id: "3d-art-03",
            title: "Sad men pencil sketch with 3d effect.",
            image: "images/3d/sad_men.jpg",
            date: "20 April 2012",
            description: "3D sketch Pencil Sketch"
        },
        {
            id: "3d-art-04",
            title: "Hulk pencil sketch with 3d effect.",
            image: "images/3d/hulk.jpg",
            date: "30 April 2012",
            description: "3D sketch Pencil Sketch"
        },
        {
            id: "3d-art-05",
            title: "Men under pit pencil sketch with 3d effect.",
            image: "images/3d/men_in_hole.jpg",
            date: "28 April 2012",
            description: "3D sketch Pencil Sketch"
        },
        {
            id: "3d-art-06",
            title: "Photo frame pencil sketch with 3d effect.",
            image: "images/3d/photo_frame.jpg",
            date: "20 May 2012",
            description: "3D sketch Pencil Sketch"
        },
        {
            id: "3d-art-07",
            title: "Rubic cude pencil sketch with 3d effect.",
            image: "images/3d/3d_cube.jpg",
            date: "13 April 2012",
            description: "3D sketch Pencil Sketch"
        },
        {
            id: "3d-art-08",
            title: "Sphere pencil sketch with 3d effect.",
            image: "images/3d/sphere.jpg",
            date: "14 April 2012",
            description: "3D sketch Pencil Sketch"
        }
    ],
    images: [
        {
            id: "art-22",
            title: "Couple Emotional Sketch",
            image: "images/sketches/sketch_23.jpg",
            date: "10 October 2018",
            description: "Couple Emotional Pencil Sketch"
        },
        {
            id: "art-27",
            title: "Indian Girl covering Her Face",
            image: "images/sketches/sketch_28.jpeg",
            date: "10 Feb 2021",
            description: "Indian Girl covering Her Face Pencil Sketch"
        },
        {
            id: "art-21",
            title: "Wild Lion Face Sketch",
            image: "images/sketches/sketch_22.jpg",
            date: "15 September 2018",
            description: "Wild Lion Face Pencil Sketch"
        },
        {
            id: "art-26",
            title: "Wild Angry Tiger Face",
            image: "images/sketches/sketch_27.jpeg",
            date: "08 July 2020",
            description: "Wild Angry Tiger Face Pencil Sketch"
        },
        {
            id: "art-19",
            title: "Irfan Khan",
            image: "images/sketches/sketch_20.jpg",
            date: "28 November 2015",
            description: "Bollywood Acter Irfan Khan Pencil Sketch"
        },
        {
            id: "art-20",
            title: "North Indian Bride",
            image: "images/sketches/sketch_21.jpg",
            date: "29 October 2017",
            description: "North Indian Bride Pencil Sketch"
        },
        {
            id: "art-23",
            title: "wolverine(Hugh Jackman)",
            image: "images/sketches/sketch_24.jpg",
            date: "05 April 2019",
            description: "Hollywood Actor Hugh Jackman Pencil Sketch"
        },
        {
            id: "art-18",
            title: "Little Girl Sketch",
            image: "images/sketches/sketch_19.jpg",
            date: "18 July 2014",
            description: "Little Girl Pencil Sketch"
        },
        {
            id: "art-15",
            title: "Female Model",
            image: "images/sketches/sketch_16.jpg",
            date: "19 july 2005",
            description: "Female Model Water Color Painting"
        },
        {
            id: "art-16",
            title: "Indian Bride Sketch",
            image: "images/sketches/sketch_18.jpg",
            date: "03 june 2007",
            description: "Indian Bride Pencil Sketch"
        },
        {
            id: "art-14",
            title: "Aishwarya Rai Sketch",
            image: "images/sketches/sketch_15.jpg",
            date: "19 May 2005",
            description: "Bollywood Actress Aishwarya Rai Color Pencil Sketch"
        },
        {
            id: "art-13",
            title: "Aishwarya Rai Sketch",
            image: "images/sketches/sketch_14.jpg",
            date: "04 October 2004",
            description: "Bollywood Actress Aishwarya Rai Sketch"
        },
        {
            id: "art-12",
            title: "American Female Model",
            image: "images/sketches/sketch_13.jpg",
            date: "07 November 2010",
            description: "American Female Model Pencil Sketch"
        },
        {
            id: "art-11",
            title: "American Female Model",
            image: "images/sketches/sketch_12.jpg",
            date: "08 August 2012",
            description: "American Female Model Pencil Sketch"
        },
        {
            id: "art-7",
            title: "Indian Female Model",
            image: "images/sketches/sketch_8.jpg",
            date: "09 july 2006",
            description: "Indian Female Model Pencil Sketch"
        },
        {
            id: "art-9",
            title: "Jessica Alba",
            image: "images/sketches/sketch_10.jpg",
            date: "17 August 2011",
            description: "Hollywood Actress Jessica Alba Pencil Sketch"
        },
        {
            id: "art-3",
            title: "Shahid Kapoor Sketch",
            image: "images/sketches/sketch_3.jpg",
            date: "27 july 2005",
            description: "Shahid Kapoor Pencil Sketch"
        },
        {
            id: "art-10",
            title: "Female Model",
            image: "images/sketches/sketch_11.jpg",
            date: "22 October 2011",
            description: "Female Model Pencil Sketch"
        },
        {
            id: "art-6",
            title: "Drew Barrymore Sketch",
            image: "images/sketches/sketch_6.jpg",
            date: "26 March 2007",
            description: "Hollywood Actress Drew Barrymore Pencil Sketch"
        },
        {
            id: "art-5",
            title: "American Female Model",
            image: "images/sketches/sketch_5.jpg",
            date: "23 October 2011",
            description: "American Female Model Pencil Sketch"
        },
        {
            id: "art-4",
            title: "Angelina Jolie Sketch",
            image: "images/sketches/sketch_4.jpg",
            date: "01 June 2007",
            description: "Angelina Jolie Pencil Sketch"
        },
        {
            id: "art-8",
            title: "Aishwarya Rai Sketch",
            image: "images/sketches/sketch_9.jpg",
            date: "07 june 2005",
            description: "Bollywood Actress Aishwarya Rai Sketch"
        },
        {
            id: "art-2",
            title: "A Women Face Sculptures",
            image: "images/sketches/sketch_2.jpg",
            date: "29 January 2006",
            description: "A Women Face Sculptures Pencil Sketch"
        },
        {
            id: "art-1",
            title: "woman waiting at door",
            image: "images/sketches/sketch_1.jpg",
            date: "20 july 2005",
            description: "A woman waiting at door"
        },{
            id: "art-25",
            title: "Running Horse",
            image: "images/sketches/sketch_26.jpg",
            date: "03 August 2019",
            description: "Running Horse Pencil Sketch"
        },
        {
            id: "art-24",
            title: "Wild Tiger Face",
            image: "images/sketches/sketch_25.jpg",
            date: "27 May 2019",
            description: "Wild Tiger Face Pencil Sketch"
        }
    ]
}

export default artData;