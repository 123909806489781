import React from 'react';

// styles
import './style.scss';

const Toast = ({senderName, error}) => {
    return <div className="darkpiece-toast" style={error ? {background: '#a10f28'} : null }>
        { 
            error ? (<p>{error}</p>)
            :
            (<p>Hi, <strong>{senderName}</strong> your message send to Saurabh successfully.</p>)
        }
    </div>;
}

export default Toast;