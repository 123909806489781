const cssDemos = {
    title: "CSS Demo Videos",
    host: "",
    description: "These CSS demos are part of my personal learning and exploring, nothing related to my current and previous Organizations.",
    videos: [{
            id: "demo-1",
            title: "CSS3 Hut Animation",
            video: "videos/hut.mp4",
            domain: "PL78ISay4sLJgLKZwqxju6YRtp2NiiBGqk",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using css properties like transition, animation, translate, transform etc."
        },
        {
            id: "demo-2",
            title: "CSS Cube Animation",
            video: "videos/cube.mp4",
            list: "PL78ISay4sLJhVm8P9I0OEbLDH-XPPV50F",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using css properties like transition, animation, transform etc."
        },
        {
            id: "demo-3",
            title: "Css Creative Shape",
            video: "videos/shape.mp4",
            list: "PL78ISay4sLJgdss4IerNNYQjK-nDccQ_S",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using css properties like background-color, animation, gradients, transform etc."
        },
        {
            id: "demo-4",
            title: "CSS 4 Tab animation",
            video: "videos/four-tab.mp4",
            list: "PL78ISay4sLJjSY-qEQNdpSSj_AwjKx8Vz",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using css properties like animation, position, transform, border-radius etc."
        },
        {
            id: "demo-5",
            title: "Text Clip Animation",
            video: "videos/name-animation.mp4",
            list: "PL78ISay4sLJjzLT6M7CUI1PkTwCi-SVK8",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using css properties like animation, background-clip, background-image etc."
        },
        {
            id: "demo-6",
            title: "CSS Ring Shadow",
            video: "videos/shadow-animation2.mp4",
            list: "PL78ISay4sLJiizGrJI8eXiZV1GAWP_3HO",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using single div with css properties like animation, box-shadow for dots and sass to dynamic calculation."
        },
        {
            id: "demo-7",
            title: "CSS Spiral Animation",
            video: "videos/shadow-animation1.mp4",
            list: "PL78ISay4sLJjAOXVYruzLDhmiXiiLPjhb",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using 10 div with css properties like animation, box-shadow for dots and sass to dynamic calculation."
        },
        {
            id: "demo-8",
            title: "Css Winning Wheel",
            video: "videos/winning-wheel.mp4",
            list: "PL78ISay4sLJiyApi85-_38h1RVJzXVXCF",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using divs with css properties like animation, transform, border-radius etc."
        },
        {
            id: "demo-9",
            title: "Css sphere",
            video: "videos/sphere.mp4",
            list: "PL78ISay4sLJha4_1AEKgBeKXiSSF2a4Do",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using divs with css properties like animation, box-shadow for dots and sass to dynamic calculation."
        },
        {
            id: "demo-10",
            title: "CSS Digit Animation",
            video: "videos/digit.mp4",
            list: "PL78ISay4sLJjb0Ja7nptSvqIys8hlkJZ_",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using single div with css properties like animation, box-shadow for square pixel and sass to dynamic calculation."
        },
        {
            id: "demo-11",
            title: "Batman",
            video: "videos/batman.mp4",
            list: "PL78ISay4sLJg30Ql5SUVc-4PZisRLEp6u",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using image with css properties like animation, background-clip, background-image etc."
        },
        {
            id: "demo-12",
            title: "CSS Text Animation",
            video: "videos/text-animation.mp4",
            list: "PL78ISay4sLJj2FiattZ81EpqpgoXkFnFp",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using css properties like transition, animation, translate, transform etc."
        },
        {
            id: "demo-13",
            title: "CSS Background Pattern",
            video: "videos/css_pattern.mp4",
            list: "PL78ISay4sLJiDHjMTbRzqpSx0ph08eTze",
            date: "Pure CSS no javascript or any other scripting language used.",
            description: "Development by using single with css properties like animation, box-shadow for circle and css-variable."
        }
    ]
}

export default cssDemos;