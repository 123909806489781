import React from "react";

// styles
import './Loading.scss';

function Loading() {
    return  <div className="dp-loading">
                <div className="dp-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <h5>Please wait...</h5>
            </div>;
}

export default Loading;

