import React, { useState, Fragment } from "react";

import Loading from './../loading/Loading';

import utility from './../../utility/util';

function Video (props) {
    const [ isLoading, setState ] = useState(true);

    return (
        <Fragment>
            {isLoading && <Loading/>}
            <video
                autoPlay
                muted
                loop
                preload="auto"
                onLoadedData={() => setState(false)}
            >
                <source src={utility.getUrl(props.video)} type="video/mp4"/>
            </video>
        </Fragment>
    )
}

export default Video;