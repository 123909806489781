import React from 'react';
import { Route } from 'react-router-dom';

// Components
import Header from './../components/header/Header';
import Footer from './../components/footer/Footer';

// Pages
import About from './about/About';
import Article from './article/Article';
import Home from './home/Home';
import Art from './arts/Art';
import Css from './css/Css';
import Apps from './apps/Apps';
import Quote from './quotes/Quote';
import Resume from './resume/';
import Messages from './messages/';

// styles
import './Darkpiece.scss';

function Darkpiece() {
  return (
    <div className="darkpiece">
      <Header/>
      <main className="darkpiece-page">
        <Route path="/about" component={ About }/>
        <Route exact path="/" component={ Home }/>
        <Route path="/apps" component={ Apps }/>
        <Route path="/css" component={ Css }/>
        <Route path="/arts" component={ Art }/>
        <Route path="/quotes" component={ Quote }/>
        <Route path="/articles" component={ Article }/>
        <Route path="/resume" component={ Resume }/>
        <Route path="/messages" component={ Messages }/>
      </main>
      <Footer/>
    </div>
  );
}

export default Darkpiece;
