

import AppData from './../apps/AppData';
import CssData from './../css/CssDemos';
import ArtData from './../arts/ArtData';

const homeData = [
    {
        title: "Apps Galary",
        id: "app-frames",
        path: "/apps",
        frames: AppData.apps.slice(0, 2)
    },
    {
        title: "CSS Galary",
        id: "css-galary",
        path: "/css",
        host: "",
        items: CssData.videos.slice(0, 3)
    },
    {
        title: "3D Art Galary",
        id: "3d-art-galary",
        path: "/arts",
        host: "https://scontent.fblr2-1.fna.fbcdn.net/v/",
        items: ArtData.images3d.slice(0, 3)
    },
    {
        title: "Art Galary",
        id: "art-galary",
        path: "/arts",
        host: "https://scontent.fblr2-1.fna.fbcdn.net/v/",
        items: ArtData.images.slice(0, 3)
    }
];

export default homeData;