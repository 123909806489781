import React from "react";

import utility from './../../utility/util';

// styles
import './SocialIcons.scss'; 

function SocialIcons () {
    return (
        <div className="social-icons">
            <div className="icons-wrapper">
                <a href="#linkedin" className="icon"><img src={utility.getUrl("images/icons/linkedin.webp")} alt="linkedin"/></a>
                <a href="https://www.facebook.com/saurabhsharma722" className="icon" target="blank_"><img src={utility.getUrl("images/icons/fb.webp")} alt="facebook"/></a>
                <a href="#twiter" className="icon"><img src={utility.getUrl("images/icons/twiter.webp")} alt="twiter"/></a>
                <a href="#insta" className="icon"><img src={utility.getUrl("images/icons/insta.webp")} alt="insta"/></a>
            </div>
        </div>
    )
}

export default SocialIcons;