import React, { Component } from "react";

import utility from './../../utility/util';
import SocialIcons from './../../components/social-icons/SocialIcons';

// styles
import './About.scss'; 

class About extends Component {
    render() {
        return  (
            <div className="darkpiece-about">
                <div className="timeline-bg">
                    <div className="page profile-timeline">
                        <div className="profile-image">
                            <img src={utility.getUrl("images/saurabh.jpg")} alt="Saurabh Sharma photograph"/>
                        </div>
                        <div className="profile-info">
                            <div className="row">
                                <h3>Saurabh Sharma</h3>
                                <h4>Lead Frontend Web Developer</h4>
                            </div>
                            <div className="row">
                                <h4>Email:</h4>
                                <p>saurabhsharma722@live.com</p>
                            </div>
                            <div className="row">
                                <h4>Mobile:</h4>
                                <p>814777XXXX</p>
                            </div>
                            <div className="row">
                                <h4>Skills:</h4>
                                <h5 className="skill excellent">CSS / SASS</h5>
                                <h5 className="skill excellent">HTML / HTML5</h5>
                                <h5 className="skill excellent">JS / JQUERY</h5>
                                <h5 className="skill good">React / Redux</h5>
                                <h5 className="skill good">NodeJS</h5>
                                <h5 className="skill ok">Angular / VueJs</h5>
                                <h5 className="skill ok">ROR / Go / SQL</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page">
                    <SocialIcons/>

                    <div className="page-intro">
                        <h3>Hello! I'm Saurabh</h3>
                        <p>
                            Lead frontend developer with 10+ years of experience in developing and managing
                            robust, scalable and secure web and mobile application from scratch in a wide array
                            of domains like Apparel & Fashion, Retail and other customs CMS
                            application. Passionate for exploring new avenues and new technologies. I will contribute
                            to the organizations by providing input & guidance for building high-quality user 
                            interfaces for responsive websites, mobile apps, and interactive features that drive
                            business growth and improve UX.
                        </p>
                    </div>
                    <div className="title-head full-width">EXPERIENCE</div>
                    <div className="full-width">
                        <div className="about-content page">
                            <div className="left-content">
                                <h3>2018-till now</h3>
                                <p>Lead Software Developer</p>
                            </div>
                            <div className="right-content">
                                <h3>LBrands</h3>
                                <ul>
                                    <li>
                                        L Brands, Inc. is an American fashion retailer based in Columbus, Ohio. Its flagship brands include Victoria's Secret and Bath & Body Works. L Brands posted $12.63 billion in revenue in 2017, and was listed as 231 on the 2018 Fortune 500 list of largest United States companies by revenue.
                                    </li>
                                    <li>
                                        <strong>Role: </strong>
                                        Responsible for building online victoriasecret.com web and mobile application,
                                        building API contracts between backend and frontend, Developed various web based components with various features, functionalities, website performance etc.
                                    </li>
                                    <li>
                                        <strong>Headquarters: </strong>
                                        Columbus, Ohio, United States
                                    </li>
                                    <li>
                                        <strong>Subsidiaries: </strong>
                                        Victoria's Secret, Bath & Body Works, Henri Bendel, more
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="about-content page">
                            <div className="left-content">
                                <h3>2014-2018</h3>
                                <p>Senior Software Developer</p>
                            </div>
                            <div className="right-content">
                                <h3>Sephora</h3>
                                <ul>
                                    <li>
                                        Sephora is a French multinational chain of personal care and beauty stores. Featuring nearly 300 brands, along with its own private label, Sephora offers beauty products including cosmetics, skincare, body, fragrance, nail color, beauty tools, and haircare.
                                    </li>
                                    <li>
                                        <strong>Role: </strong>
                                        Responsible for building online sephora.sg web and mobile, building online seasonal online web and mobile game applications for promotion.
                                        Developed various web based components with various features, functionalities, website performance etc.
                                    </li>
                                    <li>
                                        <strong>Parent organization: </strong>
                                        LVMH
                                    </li>
                                    <li>
                                        <strong>Headquarters: </strong>
                                        Headquarters: Paris, France
                                    </li>
                                    <li>
                                        <strong>Founded: </strong>
                                        1970, Paris, France
                                    </li>
                                    <li>
                                        <strong>Subsidiaries: </strong>
                                        Sephora USA, Inc., Sephora San Francisco
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="about-content page">
                            <div className="left-content">
                                <h3>2013-2014</h3>
                                <p>Senior Software Developer</p>
                            </div>
                            <div className="right-content">
                                <h3>Cerner</h3>
                                <ul>
                                    <li>
                                        Cerner Corporation is an American supplier of health information technology solutions, services, devices, and hardware. As of February 2018, its products were in use at more than 27,000 facilities around the world. The company had more than 28,000 employees globally, with over 13,000 in Kansas City, Missouri.
                                    </li>
                                    <li>
                                        <strong>Role: </strong>
                                        Responsible for building online Medication Request web application development, Developed various web based components with various features, functionalities, website performance etc
                                    </li>
                                    <li>
                                        <strong>Headquarters: </strong>
                                        North Kansas City, Missouri, United States
                                    </li>
                                    <li>
                                        <strong>Subsidiaries: </strong>
                                        Cerner Multum Inc, Cerner Lingologix Inc, Cerner ltd.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="about-content page">
                            <div className="left-content">
                                <h3>2010-2013</h3>
                                <p>Senior Software Developer</p>
                            </div>
                            <div className="right-content">
                                <h3>Honeywell</h3>
                                <ul>
                                    <li>
                                        Honeywell International Inc. is a publicly traded conglomerate headquartered in Charlotte, North Carolina, United States that produces commercial and consumer products, engineering services and aerospace systems. In 2018, Honeywell ranked 77th in the Fortune 500.
                                    </li>
                                    <li>
                                        <strong>Role: </strong>
                                        Responsible for building online Zoomba iPhone Client mobile application, online Engineering Dashboard web application online Search Portal web and mobile application.
                                    </li>
                                    <li>
                                        <strong>Headquarters: </strong>
                                            Notifier, Intermec, UOP LLC, more.
                                    </li>
                                    <li>
                                        <strong>Subsidiaries: </strong>
                                        Victoria's Secret, Bath & Body Works, Henri Bendel, more
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="title-head full-width">EDUCATION</div>
                    <div className="full-width">
                        <div className="about-content page m-col">
                            <div className="left-content">
                                <h3>2009-2010</h3>
                                <p>PG Diploma</p>
                            </div>
                            <div className="right-content">
                                <h3>C-DAC Bangalore</h3>
                                <p>Advance Computing</p>
                            </div>
                        </div>
                        <div className="about-content page m-col">
                            <div className="left-content">
                                <h3>2005-2009</h3>
                                <p>Bachelor of Engineering</p>
                            </div>
                            <div className="right-content">
                                <h3>ABES Engg. College</h3>
                                <p>Computer Science</p>
                            </div>
                        </div>
                    </div>
                    <div className="title-head full-width">CERTIFICATIONS</div>
                    <div className="full-width">
                        <div className="about-content page m-col">
                            <div className="left-content">
                                <h3>2011</h3>
                                <p>Six Sigma</p>
                            </div>
                            <div className="right-content">
                                <h3>Honeywell</h3>
                                <p>
                                    Six Sigma Green Belt
                                </p>
                            </div>
                        </div>
                        <div className="about-content page m-col">
                            <div className="left-content">
                                <h3>2013</h3>
                                <p>UX-Traning</p>
                            </div>
                            <div className="right-content">
                                <h3>Honeywell</h3>
                                <p>
                                    Human Factor
                                </p>
                            </div>
                        </div>
                        <div className="about-content page m-col">
                            <div className="left-content">
                                <h3>2013</h3>
                                <p>Photoshop</p>
                            </div>
                            <div className="right-content">
                                <h3>Honeywell</h3>
                                <p>
                                    Adobe Photoshop
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;