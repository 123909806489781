import React, { useState } from "react";

import Card from './../card/Card';

// styles
import './Cards.scss'; 

function Cards (props) {
    const [state, setState] = useState(props.cards);

    const updateSelectedTab = (selectedCard) => {
        setState(state.map(card => {
            if (card.id === selectedCard.id) {
                return {
                    ...card,
                    isSelected: true
                }
            }

            return card;
        }));
    }

    return (
        <div className="cards">
            {
                state.map(card => <Card card={card} key={ card.id } updateSelectedTab={updateSelectedTab}/>)
            }
        </div>
    )
}

export default Cards;