import React, { useState } from "react";

import Video from './../video/Video';
import utility from './../../utility/util';

// styles
import './Card.scss'; 

function Card (props) {
    const [ state, setState ] = useState(props.card);

    const showLargeView = (event) => {
        event.preventDefault();
        setState({
            ...state,
            isSelected: true
        });
    }

    const showNormalView = (event) => {
        event.stopPropagation();
        setState({
            ...state,
            isSelected: false
        });
    }

    return (
        <a
            href="#card"
            className={state.isSelected ? "card selected" : "card"}
            onClick={showLargeView}
        >
            <span
                className="close-btn"
                onClick={showNormalView}
            >
                &#10005;
            </span>
            <div className="view-display">
                {state.image && (
                    <div className="frame img-frame">
                        <img src={utility.getUrl(state.image)} alt={ state.title }/>
                    </div>
                )}
                {state.video && (
                    <div className="frame page-frame">
                        <Video video={state.video}/>
                    </div>
                )}
            </div>

            <div className="card-block">
                <h4 className="card-title">{ state.title }</h4>
                <p className="card-text">
                    { state.description }
                </p>
            </div>
            <div className="card-footer">
                <small className="text-muted">{ state.date }</small>
            </div>
        </a>
    )
}

export default Card;