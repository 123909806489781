import React, { useEffect, useState} from 'react';

// styles
import './index.scss';

const HOSTNAME = window.location.hostname === "darkpiece.com" ? "https://darkpiece-server.herokuapp.com" : "http://localhost:5000";


function Messages (props) {
    const [state, setState] = useState();

    useEffect(() => {
        fetch(`${HOSTNAME}/api/messages`)
        .then(data => data.json())
        .then(data => {
            const sortedList = data.sort();
            setState(sortedList);
        });
    }, []);


    return <div className="main-page darkpiece-app">
        {
            state && <div className="message-container page">
                {state.sort().map((msg, id) => (
                    <div key={`${msg.id}-${id}`} className="message">
                        <div className="message-head"><strong>{msg.name}</strong><span>{msg.email}</span></div>
                        {
                            msg.messages.map((item, idx) => <div key={`${item.title}-${msg.id}-${idx}`}>
                                <h3>{item.title}</h3>
                                <p>{item.message}</p>
                            </div>)
                        }
                    </div>
                ))}
            </div>
        }
</div>
}

export default Messages;