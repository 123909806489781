import React from "react";

import Cards from './../../components/cards/Cards';
import CssDemos from './CssDemos';

import "./Css.scss";

function Css () {
    const state = {
        title: CssDemos.title,
        cards: CssDemos.videos,
    }

    return (
        <div className="main-page darkpiece-css">
            <div className="css-comtainer page">
                <Cards cards={state.cards}/>
            </div>
            <div className="page-intro">
                <p>
                    {CssDemos.description}
                </p>
            </div>
        </div>
    )
}

export default Css;